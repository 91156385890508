/**
* @author zjc[beica1@outook.com]
* @date 2021/8/26 11:39
* @description
*   InviteLink.vue of WeTrade
*/
<template>
  <LinkPage />
</template>

<script lang="ts">
import LinkPage from '@/pages/invite/LinkPage.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'InviteLink',
  components: { LinkPage },
})
</script>

<style scoped>

</style>
